import React, {useState} from 'react';
import {getLink, getRoutes} from "../../../components/shared/routes";
import Layout from '../../../components/shared/Layout';
import SEO from '../../../components/shared/SEO';
import css from "./dcode_gallery.module.scss";
import AdditionalInformation
    from "../../../components/shared/additionalInformation/additionalInformation";
import Footer from "../../../components/shared/Footer/Footer";
import {graphql, useStaticQuery} from "gatsby";
import {getImagesFluid, renderImageByFileName} from "../../../components/componentsUtils";
import dcodeGallery1 from "../../../images/sections/dcodeGallery/Dcode_gallery_1.jpg"
import dcodeGallery2 from "../../../images/sections/dcodeGallery/Dcode_gallery_2.jpg"
import dcodeGallery3 from "../../../images/sections/dcodeGallery/Dcode_gallery_3.jpg"
import AppLightbox from "../../../components/shared/Lightbox/AppLightbox";
import Breadcrumbs from "../../../components/shared/Breadcrumbs/Breadcrumbs";
import BackToMobileAppsButton from "../../../components/shared/ButtonBackTo/BackToMobileAppsButton";
import RedirectTo from "../../../components/shared/RedirectTo/RedirectTo";

export default () => (<RedirectTo url={getLink([getRoutes().TechnologyMobileAppsDcodeGallery])}/>);

// const query = graphql`
// {
//   fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_dcode_gallery"}}) {
//     nodes {
//       childImageSharp {
//         fluid(maxWidth: 3400, quality: 70) {
//           ...GatsbyImageSharpFluid,
//           originalName
//         }
//       }
//     }
//   }
// }`;
//
// const DcodeGallery = () => {
//     const routes = getRoutes();
//     const pageTitle = routes.SolutionsMobileApps.pageTitle;
//     const imagesFluid = getImagesFluid(useStaticQuery(query));
//
//     const [openLightbox, setOpenLightbox] = useState(false);
//     const images = [
//         {src: dcodeGallery1, index: 0},
//         {src: dcodeGallery2, index: 1},
//         {src: dcodeGallery3, index: 2},
//     ]
//     const [lightboxImages, setLightboxImages] = useState([]);
//
//     const showPreview = (index) => {
//         setOpenLightbox(true);
//         const next = images.filter(value => value.index > index)
//         const prev = images.filter(value => value.index < index)
//         setLightboxImages([images[index], ...next, ...prev])
//     };
//
//     const onLightboxClose = () => {
//         setOpenLightbox(false);
//     };
//
//     const renderSection = (index, imageAlt = '', image) => {
//         return (
//             <div className={css.section}>
//                 <div className={css.sectionImageWrapper} onClick={() => showPreview(index)}>
//                     {renderImageByFileName(imagesFluid, image, imageAlt)}
//                 </div>
//             </div>
//         );
//     };
//
//     return (
//         <Layout>
//             <SEO title={pageTitle}/>
//
//             <Breadcrumbs
//                 title={routes.SolutionsMobileAppsDcodeGallery.pageTitle}
//                 parentTitles={[routes.Solutions.pageTitle, pageTitle]}
//                 parentRoutes={[routes.Solutions, routes.SolutionsMobileApps]}
//             />
//
//             <div className={css.wrapper}>
//                 <div className={css.title}>
//                     {routes.SolutionsMobileAppsDcodeGallery.pageTitle}
//                 </div>
//                 <div className={css.text}>
//                     <div>Check out Dcode Reader in action!</div>
//                 </div>
//                 <div className={css.dcodeInstructionWrapper}>
//                     <div className={css.wrapperBg}/>
//                     <div className={css.content}>
//                         <div className={css.bigContent}>
//                             {renderImageByFileName(imagesFluid, 'dcode_instruction.png', '')}
//                         </div>
//                         <div className={css.smallContent}>
//                             {renderImageByFileName(imagesFluid, 'dcode_instruction_m.png', '')}
//                         </div>
//                     </div>
//                 </div>
//                 <div className={css.sectionsWrapper}>
//                     {renderSection(0, "Dcode 1", "Dcode_gallery_1_s.png")}
//                     {renderSection(1, "Dcode 2", "Dcode_gallery_2_s.png")}
//                     {renderSection(2, "Dcode 3", "Dcode_gallery_3_s.png")}
//                 </div>
//                 <BackToMobileAppsButton/>
//
//                 <AdditionalInformation/>
//
//                 <AppLightbox
//                     open={openLightbox}
//                     close={onLightboxClose}
//                     slides={lightboxImages}
//                 />
//
//                 <Footer/>
//
//             </div>
//         </Layout>
//     );
// };
//
// export default DcodeGallery;
